import React, { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import moment from "moment";
import numeral from "numeral";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pdf from "@material-ui/icons/PictureAsPdf";
import Link from "@material-ui/core/Link";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import OrderAPI, { ChallanAPI, BillAPI } from "../../BL";
import { transpose, values } from "underscore";
import { yellow } from "@material-ui/core/colors";
import { $CombinedState } from "redux";
const useStyles = makeStyles({
  colorH: {
    backgroundColor: "#795548",
    "& th": {
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  colorH1: {
    backgroundColor: "#d26936",
    "& th": {
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  color1: {
    backgroundColor: "#d7ccc8",
  },
  color2: {
    backgroundColor: "#efebe9",
  },
  color3: {
    backgroundColor: "white",
  },
  color4: {
    backgroundColor: "#e5e872",
  },
  Hyperlink: {
    textDecoration: "underline",
    color: "blue",
    fontSize: "10px",
    cursor: "pointer",
  },
  done: {
    backgroundColor: 'lightgreen',
    padding: '2px 2px 2px 2px'
  },
  pending: {
    backgroundColor: 'lightpink',
    padding: '2px 2px 2px 2px'
  }
});
function makeLink(lnk) {
  return "/server/PDF/Challan/" + lnk + ".pdf";
}
function makeLink2(lnk) {
  return "/server/PDF/" + lnk + ".pdf";
}
export default function OrderList(props) {
  const obj = new OrderAPI();
  const objChallan = new ChallanAPI();
  const objBill = new BillAPI();
  const classes = useStyles();

  const [state, setState] = React.useState({
    data: [],
    deletedChallan: [],
    isLoading: false
  });
  function deleteOrder(order_id, order_number) {
    if (
      window.confirm("Do you want to delete this order (" + order_number + ")?")
    ) {
      obj
        .deleteOrderById(order_id)
        .then(() => {
          alert("Deleted successfully!");
          fetchList();
        })
        .catch((err) => alert(err));
    }
  }
  function editOrder(order_id) {
    props.handleEdit(order_id);
  }

  function openInvoicePopup(rowData) {

   
    let table = document.getElementById(rowData.order_id);

    let selected = table.dataset.selected.replace(/^,|,$/g, "").split(',');
   
if(selected[0]===""){

  alert("Please select atleast one challan! (double click on challan date)"); return;
}
    const _temp = { ...rowData }
    _temp.order_qty = 0;
    _temp.order_price = 0;
    _temp.challan_numbers = selected.join("+").toString();
    if ( selected[0] === "") {
      rowData.challan_numbers.split(',').map((m) => {

        _temp.order_qty += parseInt(m.split("|")[4]);
        _temp.order_price = parseFloat(m.split("|")[5]);
      })
    }
    else {



      rowData.challan_numbers.split(',').filter(f => f.split('|')[0] == selected.filter(s => s === f.split('|')[0])).map((m) => {

        _temp.order_qty += parseInt(m.split("|")[4]);
        _temp.order_price = parseFloat(m.split("|")[5]);
      })



    }
    props.handleAddInvoice(_temp);
  }
  function openInvoicePopupChallan(rowData, m) {
  
    const _temp = { ...rowData }
    _temp.order_qty = m.split("|")[4];
    _temp.order_price = m.split("|")[5];
    _temp.challan_numbers = m;

    props.handleAddInvoice(_temp);



  }



  function openOrderPopupAdd(rowData) {
    props.handleEdit(rowData);
  }
  function openChallanPopup(rowData) {
    props.handleAddChallan(rowData);
  }
  function openChallanUpdatePopup(challan_id, order_number) {
    props.handleUpdateChallan(challan_id, order_number);
  }
  function openInvoiceUpdatePopup(bill_id, order) {
    props.handleUpdateBill(bill_id, order);
  }
  function deleteChallan(challan_id, challan_number) {
    if (
      window.confirm(
        "Do you want to delete this challan (" + challan_number + ")?"
      )
    ) {
      objChallan
        .deleteChallanByID(challan_id)
        .then(() => fetchList())
        .catch((err) => alert(err));
    }
  }
  function deleteBill(bill_id, bill_inv_num) {
    if (
      window.confirm("Do you want to delete this bill (" + bill_inv_num + ")?")
    ) {
      objBill
        .deleteBillByID(bill_id)
        .then(() => fetchList())
        .catch((err) => alert(err));
    }
  }
  function cancelBill(bill_id, bill_inv_num, bill_status) {
    if (
      window.confirm(
        `Do you want to ${bill_status == 0 ? "un-cancel" : "cancel"
        } this bill (${bill_inv_num})?`
      )
    ) {
      objBill
        .cancelBillByID(bill_id, bill_status == 0 ? 1 : 0)
        .then(() => fetchList())
        .catch((err) => alert(err));
    }
  }
  function fetchList() {
    // setState({
    //   ...state, data: [


    //     {
    //       "order_id": 140,
    //       "order_customer_id": 132,
    //       "order_date": "2021-03-09T05:00:00.000Z",
    //       "order_demand_date": "2021-03-13",
    //       "order_customer_name": "Novamed Pharmaceuticals (Private) Ltd",
    //       "order_number": "210302125",
    //       "order_particullars": "Silica gel bags White. H.S.Code: 2811",
    //       "order_item_code": "1.03.001.00534",
    //       "order_vendor_code": "",
    //       "order_hscode": "2811  ",
    //       "order_qty_kg": "Pcs",
    //       "order_status": 1,
    //       "order_col1": null,
    //       "order_col2": null,
    //       "order_col3": null,
    //       "order_generated_on": "2021-03-13T19:03:34.000Z",
    //       "order_generated_by": "Test",
    //       "order_updated_on": null,
    //       "order_qty": 100000,
    //       "order_updated_by": null,
    //       "order_schedule": "2021-03-15|100000",
    //       "challan_numbers": "332|Novamed_Pharmaceuticals (Private) Ltd_Challan_332_415|530|2021-03-15 00:00:00|100000|0.73",
    //       "bill_invoice_num": "500|Novamed_Pharmaceuticals (Private) Ltd_500_159|1376|1|2021-03-25 00:00:00|85410",
    //       "remaining": 0,
    //       "received": 81567
    //     },
    //     {
    //       "order_id": 102,
    //       "order_customer_id": 132,
    //       "order_date": "2021-01-21T05:00:00.000Z",
    //       "order_demand_date": "2021-02-08",
    //       "order_customer_name": "Novamed Pharmaceuticals (Private) Ltd",
    //       "order_number": "2010100303",
    //       "order_particullars": "Silica gel bags White. H.S.Code: 2811",
    //       "order_item_code": "1.03.001.00987",
    //       "order_vendor_code": "",
    //       "order_hscode": "2811  ",
    //       "order_qty_kg": "Pcs",
    //       "order_status": 1,
    //       "order_col1": null,
    //       "order_col2": null,
    //       "order_col3": null,
    //       "order_generated_on": "2021-02-08T15:25:43.000Z",
    //       "order_generated_by": "Test",
    //       "order_updated_on": null,
    //       "order_qty": 150000,
    //       "order_updated_by": null,
    //       "order_schedule": "2021-02-08|150000",
    //       "challan_numbers": "306|Novamed_Pharmaceuticals (Private) Ltd_Challan_306_049|498|2021-02-23 00:00:00|150000|0.642",
    //       "bill_invoice_num": "475|Novamed_Pharmaceuticals (Private) Ltd_475_619|1351|1|2021-03-08 00:00:00|112671",
    //       "remaining": 0,
    //       "received": 107600.515
    //     },
    //     {
    //       "order_id": 101,
    //       "order_customer_id": 132,
    //       "order_date": "2020-12-09T05:00:00.000Z",
    //       "order_demand_date": "2021-02-08",
    //       "order_customer_name": "Novamed Pharmaceuticals (Private) Ltd",
    //       "order_number": "201202012",
    //       "order_particullars": "Silica gel bags White. H.S.Code: 2811",
    //       "order_item_code": "1.03.001.00987",
    //       "order_vendor_code": "",
    //       "order_hscode": "2811  ",
    //       "order_qty_kg": "Pcs",
    //       "order_status": 1,
    //       "order_col1": null,
    //       "order_col2": null,
    //       "order_col3": null,
    //       "order_generated_on": "2021-02-08T15:25:08.000Z",
    //       "order_generated_by": "Test",
    //       "order_updated_on": null,
    //       "order_qty": 550000,
    //       "order_updated_by": null,
    //       "order_schedule": "2021-02-08|550000",
    //       "challan_numbers": "287|Novamed_Pharmaceuticals (Private) Ltd_Challan_287_3629|474|2021-02-08 00:00:00|250000|0.642,301|Novamed_Pharmaceuticals (Private) Ltd_Challan_301_929|491|2021-02-12 00:00:00|300000|0.642",
    //       "bill_invoice_num": "468|Novamed_Pharmaceuticals (Private) Ltd_468_1911|1339|1|2021-02-25 00:00:00|413127",
    //       "remaining": 0,
    //       "received": 394535.485
    //     }
    //   ], deletedChallan: [
    //     {
    //       "challan_id": 308,
    //       "challan_number": 157,
    //       "challan_invoice_num": null,
    //       "challan_client_name": "Novamed Pharmaceuticals (Private) Ltd",
    //       "challan_total": 112671,
    //       "challan_qty": 150000,
    //       "challan_price": 0.642,
    //       "challan_generated_by": "test",
    //       "challan_generated_on": "2020-10-17T21:20:30.000Z",
    //       "challan_col": null,
    //       "challan_col2": null,
    //       "challan_col3": null,
    //       "challan_pdf_path": "Novamed_Pharmaceuticals (Private) Ltd_Challan_157_2030",
    //       "challan_to_emails": "",
    //       "challan_status": 0,
    //       "challan_date1": "2020-10-20T04:00:00.000Z",
    //       "challan_date2": "2020-10-17T21:20:30.000Z",
    //       "challan_cust_id": 132,
    //       "challan_particullars": "Silica gel bags White. H.S.Code: 2811",
    //       "challan_order_mumber": "20070184",
    //       "challan_item_code": "1.03.001.00987",
    //       "challan_amount": 96300,
    //       "challan_gst_rate": 17,
    //       "challan_gst_amount": 16371,
    //       "challan_sed_rate": 0,
    //       "challan_sed_amount": 0,
    //       "challan_agst_rate": 0,
    //       "challan_agst_amount": 0,
    //       "challan_vendor_code": "",
    //       "challan_hscode": "2811",
    //       "challan_qty_kg": "Pcs",
    //       "challan_no_of_container": 15,
    //       "challan_qty_per_container": 10000,
    //       "challan_total_qty_container": 150000,
    //       "challan_material_consumption": 413,
    //       "challan_paper_consumption": 27.6,
    //       "challan_no_of_container2": 0,
    //       "challan_qty_per_container2": 0,
    //       "challan_total_qty_container2": 0,
    //       "challan_lot_number": "0",
    //       "challan_order_id": null,
    //       "challan_updated_by": null
    //     },
    //     {
    //       "challan_id": 468,
    //       "challan_number": 281,
    //       "challan_invoice_num": 0,
    //       "challan_client_name": "Novamed Pharmaceuticals (Private) Ltd",
    //       "challan_total": 0,
    //       "challan_qty": 150000,
    //       "challan_price": 0.642,
    //       "challan_generated_by": "Test",
    //       "challan_generated_on": "2021-02-03T16:19:05.000Z",
    //       "challan_col": null,
    //       "challan_col2": null,
    //       "challan_col3": null,
    //       "challan_pdf_path": "Novamed_Pharmaceuticals (Private) Ltd_Challan_281_195",
    //       "challan_to_emails": "",
    //       "challan_status": 0,
    //       "challan_date1": "2021-02-03T05:00:00.000Z",
    //       "challan_date2": "2021-02-03T16:19:05.000Z",
    //       "challan_cust_id": 132,
    //       "challan_particullars": "Silica gel bags White. H.S.Code: 2811",
    //       "challan_order_mumber": "200701084",
    //       "challan_item_code": "1.03.001.00987",
    //       "challan_amount": null,
    //       "challan_gst_rate": null,
    //       "challan_gst_amount": null,
    //       "challan_sed_rate": null,
    //       "challan_sed_amount": null,
    //       "challan_agst_rate": null,
    //       "challan_agst_amount": null,
    //       "challan_vendor_code": "",
    //       "challan_hscode": "2811",
    //       "challan_qty_kg": "Pcs",
    //       "challan_no_of_container": 15,
    //       "challan_qty_per_container": 10000,
    //       "challan_total_qty_container": 150000,
    //       "challan_material_consumption": 412.5,
    //       "challan_paper_consumption": 27.6,
    //       "challan_no_of_container2": 0,
    //       "challan_qty_per_container2": 0,
    //       "challan_total_qty_container2": 0,
    //       "challan_lot_number": "0",
    //       "challan_order_id": 98,
    //       "challan_updated_by": null
    //     }
    //   ], isLoading: false
    // }); return;



    if (props.order_customer_id > 0) {
      setState({ ...state, isLoading: true });
      obj
        .fetchListById(props.order_customer_id)
        .then((res) => setState({ ...state, data: res[0], deletedChallan: res[1], isLoading: false }))
        .catch((err) => {
          alert(err);
        });

      //
    }
  }
  useEffect(() => {
    fetchList();
  }, [props.order_customer_id, props.loadAgain]);
  const changeColor = (e) => {

    try {
    let challan_number = e.target.parentNode.id;
    let table = document.getElementById(challan_number).closest('table');//.parentElement.parentElement.parentElement.parentElement.nextSibling
    let selected = table.dataset.selected.split(',');
    let idx = selected.indexOf(challan_number);
    if (idx != -1) { selected.splice(idx, 1) }
    else {

      selected.push(challan_number)
    }
    table.dataset.selected = selected.toString();

    let tr = document.getElementById(challan_number).closest('tbody');
    tr.style.background = tr.style.background == 'orange' ? 'transparent' : 'orange';

  }
  catch(e)
{
  
}
    //table.style.background =    table.style.background =='orange'?'transparent' :'orange';

    // e.target.parentNode.nextElementSibling.style.background =e.target.parentNode.nextElementSibling.style.background =='yellow'?'transparent' :'yellow'
  }

  function getParentNode(el, tagName) {
    while (el && el.parentNode) {
      el = el.parentNode;

      if (el && el.tagName == tagName.toUpperCase()) {
        return el;
      }
    }

    return null;
  }
  function isCreated(inv_arr,_challan_number) {

   var _inv_number =""
    try{
    for(var i=0; i<inv_arr.length;i++){
      try{
      var inv = inv_arr[i];
      var inv_challan =inv.split("|")[6];
      if(_challan_number == inv_challan){
        _inv_number= inv.split("|")[0]
      }
      else{

        inv_challan.split("+").map(elm=>{
          
          if(_challan_number==elm){
            _inv_number= inv.split("|")[0]
          }
        })
      }
     
  
    }
    catch(ex){
   console.log(ex)
    }
    }
  }
  catch(ex){}
return _inv_number;
  }

  return (
    <div>
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          This is a success message!
        </Alert>
      </Snackbar> */}

      {state.isLoading ? (
        <h2>Loading...</h2>
      ) : (
        <Table size="small" padding="none">
          <TableHead>
            <TableRow className={classes.colorH}>
              <TableCell align="center" className="action" padding="none" size="small" width="100">
                Action
              </TableCell>
              <TableCell align="center" width="20">
                Order
              </TableCell>
              <TableCell align="center" width="120">
                Date
              </TableCell>
              <TableCell align="center" width="120">
                Schedule
              </TableCell>
              <TableCell align="center" width="200">Particullars</TableCell>
              <TableCell align="center" width="90">
                Qty
              </TableCell>
              <TableCell align="center" width="90">Remain</TableCell>
              <TableCell align="center" width="150">Challan(s)</TableCell>
              <TableCell align="center" width="150">Invoice#</TableCell>
              <TableCell align="center" width="90">Received</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.data.map((rowData, idx) => (
                <TableRow key={idx}>
                  <TableCell className="action">
                    <IconButton
                      color="primary"
                      aria-label="Add More"
                      component="span"
                      size="small"
                      title="Add More."
                      onClick={() => openOrderPopupAdd(rowData)}
                    >
                      <Add />
                    </IconButton>
                    <IconButton
                      color="primary"
                      title="Delete Order."
                      aria-label="Delete Order."
                      component="span"
                      size="small"
                      onClick={() =>
                        deleteOrder(rowData.order_id, rowData.order_number)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="Edit Order"
                      component="span"
                      size="small"
                      onClick={() => editOrder(rowData.order_id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">{rowData.order_number}</TableCell>
                  <TableCell align="center">
                    {moment(rowData.order_date)
                      .tz("Asia/Karachi")
                      .format("DD-MM-YY")}
                  </TableCell>
                  
                  <TableCell align="center">
                    <table 
                      width="170px"
                      border="1"
                      cellPadding="1"
                      cellSpacing="0"
                      style={{ border: "solid black 1px" }}> 
                      <tbody>{
                      rowData.order_schedule?.split(",").map((m,idx) => (
                       
                          <tr key={idx}>
                            <td align="center" width="70">
                              {moment(m.split("|")[0])
                                .tz("Asia/Karachi")
                                .format("DD-MM-YY")}
                            </td>
                            <td align="right">
                              {m.split("|")[1]} &nbsp;{" "}
                              <span
                                style={{ color: "gray", fontSize: "9px" }}
                              >
                                {rowData.order_qty_kg}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    </table>
                  </TableCell>
                  <TableCell align="center">{rowData.order_particullars}</TableCell>
                  <TableCell align="right">
                    {`${rowData.order_qty}`}{" "}
                    <span style={{ color: "gray", fontSize: "9px" }}>
                      {rowData.order_qty_kg}
                    </span>
                  </TableCell>
                  <TableCell align="center"  >
                    <span className={rowData.remaining > 0 ? classes.pending : classes.done}>{numeral(rowData.remaining).format("0,0").replace('-', "+")}
                      <span style={{ color: "gray", fontSize: "9px" }}>
                        &nbsp;&nbsp;{rowData.order_qty_kg}
                      </span>
                    </span>

                  </TableCell>
                  <TableCell className={classes.color1}>
                    <table>
                      <tbody>
                      <tr>
                        <td align="center">
                          {" "}
                          <IconButton
                            color="primary"
                            aria-label="Create Challan"
                            component="span"
                            size="small"
                            title="Create Challan"
                            onClick={() => openChallanPopup(rowData)}
                          >
                            <Add />
                          </IconButton>
                        </td>

                        <td>
                          <table
                            width="210px"
                            border="1"
                            cellPadding="1"
                            cellSpacing="0"
                            style={{ border: "solid black 1px" }}
                            data-selected=""
                            id={rowData.order_id}
                          >
                           
                            {rowData.challan_numbers?.split(",").map((m,idx) => {
                            var _challan_number =  m.split("|")[0];
                         
                        
                            var _invoice_number = isCreated(rowData.bill_invoice_num?.split(","),_challan_number);
                           console.log(_invoice_number)
                   var _backgroundColor = _invoice_number?"lightgreen":""
                           return (
                               <tbody style={{backgroundColor:_backgroundColor}}  key={idx}>
                                <tr onDoubleClick={_invoice_number==""&&changeColor} id={m.split("|")[0]} >
                                  <td align="center">
                                    {moment(m.split("|")[3])
                                      .tz("Asia/Karachi")
                                      .format("DD-MM-YY")}
                                  </td>
                                  <td align="right">
                                    {m.split("|")[4]} &nbsp;{" "}
                                    <span
                                      style={{ color: "gray", fontSize: "9px" }}
                                    >
                                      {rowData.order_qty_kg}
                                    </span>
                                  </td>
                                  <td align="right">
                                    {m.split("|")[5]} &nbsp;{" "}
                                    <span
                                      style={{ color: "gray", fontSize: "9px" }}
                                    >
                                      Rs
                                    </span>
                                  </td>
                                </tr>
                                <tr >
                                  <td align="center" valign="middle">
                                    <Link

                                      component="button"
                                      variant="body2"
                                      onClick={() => {
                                        openChallanUpdatePopup(
                                          m.split("|")[2],
                                          rowData.order_number
                                        );
                                      }}
                                    >
                                      {m.split("|")[0]}
                                    </Link>
                                    &nbsp;{" "}
                                    <span
                                      style={{ color: "gray", fontSize: "9px" }}
                                    >
                                      #
                                    </span>
                                  </td>
                                  <td align="center" colSpan="2" valign="middle">
                                    <>
                                      <a
                                        rel="noopener noreferrer"
                                        href={makeLink(m.split("|")[1])}
                                        target="_blank"
                                      >
                                        <Pdf></Pdf>
                                      </a>

                                      <a
                                        style={{
                                          cursor: "pointer",
                                          color: "maroon",
                                        }}
                                        onClick={() =>
                                          deleteChallan(
                                            m.split("|")[2],
                                            m.split("|")[0]
                                          )
                                        }
                                      >
                                        <Delete></Delete>
                                      </a> &nbsp; 
                                      <a>{_invoice_number}</a>
                                      {/* <a
                                        style={{
                                          cursor: "pointer",
                                          color: "maroon",
                                        }}
                                        title="Create challan's invoice."

                                        onClick={() => openInvoicePopupChallan(rowData, m)}

                                      >
                                        +Inv.
                                      </a> */}
                                    </>
                                  </td>
                                </tr>
                                </tbody>
                              
                            )
                            
                                    })}
                            
                        {
              state.deletedChallan.filter(f=>f.challan_order_id==rowData.order_id).map((rowData1, idx) => (

                        <tbody  style={{
                          backgroundColor: "yellow"
                        }}>
                              <tr  >
                                <td align="center">
                                  {moment(rowData1.challan_date1)
                                    .tz("Asia/Karachi")
                                    .format("DD-MM-YY")}
                                </td>
                                <td align="right">
                                  {rowData1.challan_qty}  &nbsp;{" "}
                                  <span
                                    style={{ color: "gray", fontSize: "9px" }}
                                  >
                                    {rowData1.challan_qty_kg}
                                  </span>
                                </td>
                                <td align="right">
                                  {rowData1.challan_price} &nbsp;{" "}
                                  <span
                                    style={{ color: "gray", fontSize: "9px" }}
                                  >
                                    Rs
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td align="center" valign="middle">
                                  <Link
                                    disabled
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                      openChallanUpdatePopup(
                                        rowData1.challan_pdf_path,
                                        rowData1.order_number
                                      );
                                    }}
                                  >
                                    {rowData1.challan_number}
                                  </Link>
                                  &nbsp;{" "}
                                  <span
                                    style={{ color: "gray", fontSize: "9px" }}
                                  >
                                    #
                                  </span>
                                </td>
                                <td align="center" colSpan="2" valign="middle">
                                  <a
                                    rel="noopener noreferrer"
                                    href={makeLink(rowData1.challan_pdf_path)}
                                    target="_blank"
                                  >
                                    <Pdf></Pdf>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
            
            
            
            )
              
              )}
                          </table>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </TableCell>
                  <TableCell className={classes.color1}>
                    {rowData.challan_numbers?.length > 0 &&
                      <table>
                        <tbody>
                        <tr>
                     
                            <td align="center">
                              {" "}
                              <IconButton
                                color="primary"
                                aria-label="Create Invoice"
                                component="span"
                                size="small"
                                title="Create Invoice"
                                onClick={() => openInvoicePopup(rowData)}
                              >
                                <Add />
                              </IconButton>
                            </td>
                         
                          <td valign="top">
                            <table
                              width="150px"
                              border="1"
                              cellPadding="1"
                              cellSpacing="0"
                              style={{ border: "solid black 1px" }}
                            >
                              {rowData.bill_invoice_num?.split(",").map((m) => (
                                <tbody>
                                  <tr>
                                    <td align="center">
                                      {moment(m.split("|")[4])
                                        .tz("Asia/Karachi")
                                        .format("DD-MM-YY")}{" "}
                                    </td>
                                    <td align="right">
                                      {m.split("|")[5]}&nbsp;{" "}
                                      <span
                                        style={{ color: "gray", fontSize: "9px" }}
                                      >
                                        Rs
                                      </span>
                                    </td>
                                  </tr>
                                  <tr
                                    style={{
                                      backgroundColor:
                                        m.split("|")[3] == 0 ? "yellow" : "",
                                    }}
                                  >
                                    <td align="center"  style={{backgroundColor:rowData.chbill_bill_ids?.indexOf(m.split("|")[0])>-1?'lightgreen':''}} >
                                      <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                          openInvoiceUpdatePopup(
                                            m.split("|")[2],
                                            rowData
                                          );
                                        }}
                                      >
                                      {m.split("|")[0]}
                                      </Link>
                                      &nbsp;{" "}
                                      <span
                                        style={{ color: "gray", fontSize: "9px" }}
                                      >
                                        #
                                      </span>
                                    </td>
                                    <td align="center" valign="middle">
                                      <a
                                        rel="noopener noreferrer"
                                        href={makeLink2(m.split("|")[1])}
                                        target="_blank"
                                      >
                                        <Pdf></Pdf>
                                      </a>
                                      <a
                                        style={{
                                          cursor: "pointer",
                                          color: "maroon",
                                        }}
                                        onClick={() =>
                                          deleteBill(
                                            m.split("|")[2],
                                            m.split("|")[0]
                                          )
                                        }
                                      >
                                        <Delete></Delete>
                                      </a>
                                      &nbsp;
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          cancelBill(
                                            m.split("|")[2],
                                            m.split("|")[0],
                                            m.split("|")[3]
                                          )
                                        }
                                      >
                                        <Cancel></Cancel>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    }



                  </TableCell>
                  <TableCell className={classes.color1} padding="checkbox" align="right"><b>
                    {numeral(rowData.received).format('0,0')}</b></TableCell>
                </TableRow>
              ))}
            {/* {
              state.deletedChallan.map((rowData, idx) => (
                <TableRow key={idx}>
                  <TableCell>

                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                  </TableCell>
                  <TableCell align="center">

                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="right">

                  </TableCell>
                  <TableCell align="center"  >


                  </TableCell>
                  <TableCell style={{ backgroundColor: 'yellow' }} >
                    <table>
                      <tr>
                        <td align="center" width="31">

                        </td>

                        <td>
                          <table
                            width="210px"
                            border="1"
                            cellPadding="1"
                            cellSpacing="0"
                            style={{ border: "solid black 1px" }}
                          >

                            <tbody>
                              <tr>
                                <td align="center">
                                  {moment(rowData.challan_date1)
                                    .tz("Asia/Karachi")
                                    .format("DD-MM-YY")}
                                </td>
                                <td align="right">
                                  {rowData.challan_qty}  &nbsp;{" "}
                                  <span
                                    style={{ color: "gray", fontSize: "9px" }}
                                  >
                                    {rowData.challan_qty_kg}
                                  </span>
                                </td>
                                <td align="right">
                                  {rowData.challan_price} &nbsp;{" "}
                                  <span
                                    style={{ color: "gray", fontSize: "9px" }}
                                  >
                                    Rs
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td align="center" valign="middle">
                                  <Link
                                    disabled
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                      openChallanUpdatePopup(
                                        rowData.challan_pdf_path,
                                        rowData.order_number
                                      );
                                    }}
                                  >
                                    {rowData.challan_number}
                                  </Link>
                                  &nbsp;{" "}
                                  <span
                                    style={{ color: "gray", fontSize: "9px" }}
                                  >
                                    #
                                  </span>
                                </td>
                                <td align="center" colSpan="2" valign="middle">
                                  <a
                                    rel="noopener noreferrer"
                                    href={makeLink(rowData.challan_pdf_path)}
                                    target="_blank"
                                  >
                                    <Pdf></Pdf>
                                  </a>
                                </td>
                              </tr>
                            </tbody>

                          </table>
                        </td>
                      </tr>
                    </table>
                  </TableCell>
                  <TableCell className={classes.color1}>



                  </TableCell>
                  <TableCell className={classes.color1} padding="checkbox" align="right"></TableCell>
                </TableRow>
              ))} */}
          </TableBody>
        </Table>
      )}
    </div>
  )
}
